import { Loader } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import tooltipImg from '../../assets/img/Pobierający/tooltip.svg';
import useInput from '../../hooks/useInput';

const registerNewClient = async ({ client, endpoint }) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}/${endpoint}/register`,
		{
			method: 'POST',
			body: JSON.stringify(client),
			headers: {
				'content-type': 'application/json',
			},
		}
	);

	let data = undefined;

	try {
		data = await response.json();
	} catch (e) {
		throw new Error('Błąd serwera!');
	}

	if (!response.ok) {
		if (response.status === 500) {
			throw new Error('Błąd serwera!');
		}
		if (response.status === 401) {
			throw new Error('Błąd!');
		}
		if (response.status === 422) {
			if (data.detail.includes('password')) {
				throw new Error(
					'Hasło musi zawierać conajmniej 8 znaków, jedną dużą literę, jedną małą literę i jedną cyfrę!'
				);
			} else {
				throw new Error('Podany adres e-mail istnieje już w naszej bazie.');
			}
		}
	}

	return data;
};

const Register = ({ setIsRegister, endpoint }) => {
	const [isGroup, setIsGroup] = useState(false);
	const [isAccepted, setIsAccepted] = useState(null);
	const [error, setError] = useState(false);

	const {
		value: enteredName,
		isTouched: enteredNameIsTouched,
		hasError: enteredNameHasError,
		enteredValueChangeHandler: enteredNameChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredEmail,
		isTouched: enteredEmailIsTouched,
		hasError: enteredEmailHasError,
		enteredValueChangeHandler: enteredEmailChangeHandler,
	} = useInput((enteredValue) =>
		enteredValue?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	);

	const {
		value: enteredPassword,
		isTouched: enteredPasswordIsTouched,
		enteredValueChangeHandler: enteredPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredRepeatedPassword,
		isTouched: enteredRepeatedPasswordIsTouched,
		enteredValueChangeHandler: enteredRepeatedPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const isPasswordValid = enteredPassword === enteredRepeatedPassword;
	const isFormValid =
		enteredNameIsTouched &&
		!enteredNameHasError &&
		enteredEmailIsTouched &&
		!enteredEmailHasError &&
		isGroup !== undefined &&
		enteredPassword === enteredRepeatedPassword;

	const registerNewClientMutate = useMutation(registerNewClient);

	const registerClientHandler = (e) => {
		e.preventDefault();
		setError(false);

		if (isFormValid && isAccepted) {
			registerNewClientMutate.mutate({
				client: {
					isGroup,
					nick: enteredName,
					email: enteredEmail,
					password: enteredPassword,
				},
				endpoint,
			});
		} else if (isFormValid && !isAccepted) {
			setIsAccepted(false);
		} else {
			setError(true);
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
		>
			{!registerNewClientMutate.isSuccess && (
				<>
					<h2 className="mp-flex-white__title">
						Zarejestruj się jako <br />
						pobierający
					</h2>

					<div className="mp-flex-white__subtitle">WYBIERZ RODZAJ KONTA</div>

					<form onSubmit={registerClientHandler}>
						<div className="radio-selector-container">
							<div className="form__radio">
								<label className="radio" htmlFor="f-default-customer">
									<input
										checked={!isGroup}
										onChange={() => setIsGroup(false)}
										type="radio"
										name="f-default-customer-company"
										id="f-default-customer"
										className="radio__input js-radio"
									/>
									<span className="radio__text">
										<span className="radio__label">Indywidualne</span>
									</span>
								</label>
							</div>

							<div className="form__radio">
								<label className="radio" htmlFor="f-default-company">
									<input
										checked={isGroup}
										onChange={() => setIsGroup(true)}
										type="radio"
										name="f-default-customer-company"
										id="f-default-company"
										className="radio__input js-radio"
									/>
									<span className="radio__text">
										<span className="radio__label">Grupowe </span>
									</span>
								</label>
							</div>
							<div className="tooltip-container">
								<div className="tooltip-container__img">
									<img src={tooltipImg} alt="tooltip" />
								</div>
								<div className="tooltip-container__tooltip">Konto firmowe</div>
							</div>
						</div>

						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredNameChangeHandler(e)}
									type="text"
									name="f-login"
									placeholder="Login*"
									maxLength={180}
									className="form__input"
									id="f-login"
								/>
							</div>
							{enteredNameHasError && enteredNameIsTouched && (
								<motion.div
									initial={{ opacity: 0, y: 5 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{
										default: {
											duration: 0.3,
										},
									}}
									className="form__group"
								>
									<div className="form__field">
										<span className="error">
											Wprowadzony login jest za krótki!
										</span>
									</div>
								</motion.div>
							)}
						</div>

						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredEmailChangeHandler(e)}
									type="text"
									name="f-email"
									placeholder="Adres e-mail*"
									className="form__input"
									id="f-email"
								/>
							</div>
						</div>

						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredPasswordChangeHandler(e)}
									type="password"
									name="f-pass1"
									placeholder="Hasło*"
									className="form__input"
									id="f-pass1"
								/>
							</div>
						</div>
						<div className="form__group">
							<div className="form__field">
								<input
									onChange={(e) => enteredRepeatedPasswordChangeHandler(e)}
									type="password"
									name="f-pass2"
									placeholder="Powtórz hasło*"
									className="form__input"
									id="f-pass2"
								/>
							</div>
						</div>
						{!isPasswordValid &&
							enteredPasswordIsTouched &&
							enteredRepeatedPasswordIsTouched && (
								<motion.div
									initial={{ opacity: 0, y: 5 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{
										default: {
											duration: 0.3,
										},
									}}
									className="form__group"
								>
									<div className="form__field">
										<span className="error">Hasła muszą być takie same!</span>
									</div>
								</motion.div>
							)}

						<div className="form__group">
							<div className="form__field">
								<div className="form__checkbox">
									<label className="checkbox" htmlFor="f-terms">
										<input
											onChange={() => setIsAccepted((prevState) => !prevState)}
											type="checkbox"
											name="f-tooltips"
											id="f-terms"
											className="checkbox__input js-checkbox required"
										/>
										<span
											className="checkbox__text checkbox__text--small"
											style={{ textAlign: 'left' }}
										>
											<span className="checkbox__label">
												Akceptuję Warunki korzystania ze strony, Przyzwolenia i
												ograniczenia dla pobrań, Politykę prywatności oraz
												Warianty a opłaty licencyjne.
											</span>
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="form__group">
							<div className="text-small-info">
								Pamiętaj, że przed dokonaniem pobrań musisz uzupełnić wszystkie
								dane rejestracyjne zamieszczone na Twoim koncie.
							</div>
						</div>

						<div className="form__actions mb-20">
							<button className="btn btn--block">
								{registerNewClientMutate.isLoading ? (
									<div>
										<Loader size={12} color={`#ffffff`} />
									</div>
								) : (
									<div>Zarejestruj się</div>
								)}
							</button>
						</div>
						{error && (
							<motion.div
								initial={{ opacity: 0, y: 5 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{
									default: {
										duration: 0.3,
									},
								}}
								className="form__group"
							>
								<div className="form__field">
									<span className="error">Proszę uzupełnić formularz!</span>
								</div>
							</motion.div>
						)}
						{isAccepted === false && (
							<motion.div
								initial={{ opacity: 0, y: 5 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{
									default: {
										duration: 0.3,
									},
								}}
								className="form__group"
							>
								<div className="form__field">
									<span className="error">
										Prosimy zaakceptować warunki korzystania z serwisu
									</span>
								</div>
							</motion.div>
						)}
						{registerNewClientMutate.isError && (
							<div className="form__actions mb-20">
								<p className="error">{registerNewClientMutate.error.message}</p>
							</div>
						)}

						<div className="form__group">
							<div className="account-info-check">
								<span>Posiadasz już konto?</span>
								<button onClick={() => setIsRegister(false)}>
									Zaloguj się
								</button>
							</div>
						</div>
					</form>
				</>
			)}
			{registerNewClientMutate.isSuccess && (
				<>
					<h2 className="mp-flex-white__title success">
						Rejestracja zakończona pomyślnie!
					</h2>

					<div className="mp-flex-white__subtitle">
						Aby dokończyć proces rejestracji, kliknij w link wysłany w
						wiadomości na podany adres email!
					</div>
				</>
			)}
		</motion.div>
	);
};

export default Register;
