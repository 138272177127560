import React, { useState } from 'react';
import { Modal } from '@mantine/core';
import { useMutation, useQueryClient } from 'react-query';
import { authorizedPatchWithId } from '../../fetchFunctions';
import moment from 'moment';

const WhiteBoxItem = ({ item }) => {
	const queryClient = useQueryClient();

	const [opened, setOpened] = useState(false);
	const [downloaded, setDownloaded] = useState(item.isAlreadyDownloaded);

	const markImageAsDownloadedMutation = useMutation(authorizedPatchWithId);
	const download = (e) => {
		let fileType = e.split('.').at(-1);

		fetch(e, {
			method: 'GET',
			headers: {},
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `image.${fileType ? fileType : 'jpg'}`);
					document.body.appendChild(link);
					link.click();
				});
			})
			.then(() => {
				markImageAsDownloadedMutation.mutate(
					{
						url: `/purchase_items/${item.id}/mark_as_downloaded`,
						data: {
							isDownloaded: 'string',
						},
					},
					{
						onSuccess: () => {
							queryClient.invalidateQueries(['purchase_items']);
							setDownloaded(true);
						},
					}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const downloadConditions = item?.licenceAttributes;

	const content = getContent(downloadConditions);

	return (
		<div className="white-box">
			<div className="white-box__inner">
				<div className="materials">
					<div className="materials__wrapper" style={{ alignItems: 'center' }}>
						<div
							className="materials__photo"
							style={{ width: 256, display: 'flex', justifyContent: 'center' }}
						>
							<img
								style={{ maxWidth: '100%', maxHeight: 256 }}
								src={item.image.thumbnailWatermarkImage.contentUrl}
								alt="box_photo"
							/>
						</div>
						<div className="materials__info">
							<div className="materials__info-item">
								<strong>Data wykonania:</strong>{' '}
								{moment(
									item?.image?.imageGroup?.publisherImageGroupCreatedAt
								).format('DD.MM.YYYY')}
							</div>
							<div className="materials__info-item">
								<strong>Dostawca:</strong>{' '}
								{item?.image?.imageGroup?.publisher.insideId}
							</div>
							<div className="materials__info-item">
								{' '}
								<strong>Autor:</strong> {item?.image?.imageGroup?.finalAuthor}
							</div>
							<div className="materials__info-item">
								<strong>Opis: </strong>
								{item?.image?.imageGroup?.finalDescription}
							</div>
						</div>

						<div className="materials__download">
							<a
								onClick={(e) => {
									e.preventDefault();
									setOpened(true);
								}}
								href="/"
								className="materials__download-button"
							>
								{' '}
								<i className="ico ico-licence-icon"></i> ZOBACZ WARUNKI POBRANIA
							</a>

							<div className="materials__info-item">
								<strong>Nr zapisu pobrania:</strong> {item.downloadCode}
							</div>
							<div className="materials__info-item">
								{downloaded ? (
									<>
										<strong>Data pobrania:</strong>{' '}
										{moment(item.alreadyDownloadedAt).format('DD.MM.YYYY')}
									</>
								) : (
									<a
										onClick={(e) => {
											e.preventDefault();
											download(item.image?.originalImage?.contentUrl);
										}}
										href="/"
										className="materials__download-button"
										style={{ justifyContent: 'flex-end', marginTop: 30 }}
									>
										<i className="ico ico-download2-icon"></i> Pobierz obraz
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={() => setOpened(false)}
				size="xl"
			>
				<div className="modal__inner">
					<div className="modal__wrapper">
						<h3 className="modal__title">Warunki pobrania</h3>
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault();
								setOpened(false);
							}}
							className="modal__close-button js-modal-close"
						>
							<i className="ico ico-close-icon"></i>
						</a>
					</div>
					<form
						style={{
							width: '100%',
							marginBottom: 0,
						}}
						id="downloadConditionsForm"
						className="form form__short-selects"
						data-select2-id="downloadConditionsForm"
					>
						<div className="row" data-select2-id="21">
							<div className="col-md-6" data-select2-id="20">
								{content}

								<div className="form__group mt-30">
									<label htmlFor="f-number" className="form__label">
										OPŁATA LICENCYJNA
									</label>
									<div className="price price--40 licencePrice">
										{item.price / 100} zł
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form__group">
									<a className="js-open-hidden-info">
										<label
											htmlFor="f-additional-info"
											className="form__label"
											style={{ cursor: 'pointer' }}
										>
											INFORMACJE DODATKOWE
										</label>
									</a>
									<div className="form__field js-hidden-info">
										<textarea
											disabled
											name="f-additional-info"
											readOnly
											rows={18}
											value={item.licenceAttributes.map(
												(licence) => licence?.settingLicencePricing?.title
											)}
											className="downloadConditionsInfo form__textarea required"
											id="f-additional-info"
										></textarea>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
};

function getContent(conditions) {
	switch (conditions[0].settingLicencePricing.code[0]) {
		case 'S':
			return (
				<>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							WARUNKI POBRANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								Uproszczone
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							ROZMIAR
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('S_LD_SDT')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							LICZBA UPRAWNIONYCH
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('S_MM_PAD')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
				</>
			);
		case 'M':
			return (
				<>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							WARUNKI POBRANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								Medialne
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							INTERPRETACJA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('M_DC_M')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							WYŁĄCZNOŚĆ
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('M_DC_EU')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							ZASIĘG TERYTORIALNY
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('M_RD_TR')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							CZAS WYKORZYSTANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('M_RD_TU')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							LICZBA REPRODUKCJI
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('M_RD_NR')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
				</>
			);
		case 'C':
			return (
				<>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							WARUNKI POBRANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								Komercyjne
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							DYSTRYBUCJA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_DC_DER')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							MIEJSCE ULOKOWANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_DC_PAP')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							WYŁĄCZNOŚĆ
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_DC_EU')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							ZASIĘG TERYTORIALNY
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_RD_TR')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							CZAS WYKORZYSTANIA
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_RD_TU')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
					<div className="form__group" data-select2-id="19">
						<label htmlFor="f-default-5" className="form__label">
							LICZBA REPRODUKCJI Do 5 tyś sztuk
						</label>
						<div className="form__field" data-select2-id="18">
							<div
								style={{
									backgroundColor: '#fff',
									border: '2px solid #BCD3E5',
									padding: 10,
								}}
							>
								{
									conditions.find((dc) =>
										dc.settingLicencePricing.code.includes('C_RD_NR')
									)?.settingLicencePricing.title
								}
							</div>
						</div>
					</div>
				</>
			);
		default:
			return '';
	}
}

export default WhiteBoxItem;
