import React, { useState } from 'react';
import { LoadingOverlay, Modal, Tooltip } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { authorizedGet, authorizedPost } from '../../fetchFunctions';
import { useSelector } from 'react-redux';
import axios from 'axios';

const LibarySideMenu = ({ chosenCat, setChosenCat, unathLib }) => {
	const queryClient = useQueryClient();

	const authLibQuery = useQuery(
		'clientLibrary',
		() => authorizedGet('/client_libraries'),
		{
			placeholderData: [],
			enabled: !unathLib,
		}
	);

	const unathLibQuery = useQuery(
		'questLibrary',
		async () => {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/client_libraries`,
				{
					params: unathLib,
				}
			);
			return response.data;
		},
		{
			placeholderData: [],
			enabled: !!unathLib,
		}
	);

	const libData = !!unathLib ? unathLibQuery.data : authLibQuery.data;

	const [opened, setOpened] = useState(false);
	const [newCategory, setNewCategory] = useState('');

	const user = useSelector((state) => state.auth.user);

	const cartQuery = useQuery(
		['cart', user?.userCartId],
		() => authorizedGet(`/carts/${user?.userCartId}`),
		{
			enabled: !!user,
		}
	);

	const createLibMutation = useMutation(authorizedPost, {
		onMutate: () => {
			setOpened(false);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('clientLibrary');
		},
	});

	const createUnathLibMutation = useMutation(authorizedPost, {
		onMutate: () => {
			setOpened(false);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('questLibrary');
		},
	});

	return (
		<>
			<aside className="sidenav">
				<div
					className="sidenav-container"
					style={{
						position: 'relative',
					}}
				>
					<LoadingOverlay
						visible={
							createLibMutation.isLoading || createUnathLibMutation.isLoading
						}
						overlayColor="transparent"
					/>
					{!!user && (
						<Tooltip
							label="Nie dodałeś żadnego obrazu"
							disabled={cartQuery.data?.items.length}
						>
							<div>
								<a
									href={`${process.env.REACT_APP_SITE_URL}photos/${cartQuery.data?.items[0]?.image.imageGroup.id}/${cartQuery.data?.items[0]?.image.id}?openCart=true`}
									className="sidenav__item btn btn--primary"
									style={{
										pointerEvents: !cartQuery.data?.items.length
											? 'none'
											: 'auto',
									}}
								>
									Koszyk
								</a>
							</div>
						</Tooltip>
					)}
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpened(true);
						}}
						className="sidenav__item sidenav__item--add-category btn btn--primary"
					>
						+ Dodaj kategorię
					</a>
					{libData?.map((cat) => (
						<a
							key={cat.id}
							className={`sidenav__item btn ${
								chosenCat !== cat ? 'btn--outline' : ''
							}`}
							href="/"
							onClick={(e) => {
								e.preventDefault();
								setChosenCat(cat);
							}}
						>
							{cat.title}
						</a>
					))}
				</div>
			</aside>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={() => setOpened(false)}
				size="lg"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpened(false);
						}}
						className="modal__close js-modal-close"
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Dodaj kategorię</h3>
						<form className="form js-validate">
							<input
								onChange={(e) => {
									setNewCategory(e.target.value);
								}}
								type="text"
								name="f-cat"
								placeholder="Wpisz nazwę kategorii"
								className="form__input required"
								id="f-cat"
							/>
						</form>
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault();

								!!unathLib
									? createUnathLibMutation.mutate({
											url: '/client_libraries',
											data: {
												...unathLib,
												title: newCategory,
												isDefault: false,
											},
									  })
									: createLibMutation.mutate({
											url: '/client_libraries',
											data: {
												client: user.userId,
												email: user.email,
												title: newCategory,
												pin: 'string',
												isDefault: false,
											},
									  });
							}}
							className="sidenav__item sidenav__item--add-category btn btn--primary"
						>
							+ Dodaj kategorię
						</a>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LibarySideMenu;
