import {createSlice} from "@reduxjs/toolkit";


const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: []
    },
    reducers: {
        addToCart(state, action) {
            state.items = [...state.items, action.payload]
        },
        deleteFromCart(state, action) {
            state.items = state.items.filter(i => i.dataImageId !== action.payload.dataImageId)
        },
        cleanCart(state, action) {
            state.items = []
        }
    }
})

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;