import React from 'react';

const SubHeaderLib = ({ lib }) => {
	return (
		<div className="subheader fixed-menu2">
			<div className="container">
				<div className="panel">
					<div className="panel__inner">
						<div className="panel__title">Biblioteka {lib.email}</div>
						<div className="panel__nav">
							<a
								onClick={(e) => {
									e.preventDefault();
								}}
								href="/"
								className="panel__item hidden-sm"
							>
								<i className="ico ico-library-icon"></i>
								<span className="panel__item-name">Twoja biblioteka</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubHeaderLib;
