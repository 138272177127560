import { LoadingOverlay } from '@mantine/core';
import React from 'react';

export default function Loading() {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				height: '100vh',
				width: '100vw',
				zIndex: 100,
				overflow: 'hidden',
			}}
		>
			<LoadingOverlay zIndex={100} overlayOpacity={1} visible />
		</div>
	);
}
