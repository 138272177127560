import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getConversations, getMailQueues } from '../../api/get';
import {
	formatDateForPresentation,
	formatTimeForPresentation,
	formateExactDate,
} from '../../utils';
import { useDisclosure } from '@mantine/hooks';
import { FileButton, Loader, Modal, Text } from '@mantine/core';
import { useSelector } from 'react-redux';
import Conversation from './Conversation';
import { postConversation, sendMessage } from '../../api/post';

const Conversations = () => {
	const { user } = useSelector((store) => store.auth);

	const [isOpen, modalControls] = useDisclosure(false);

	const conversationsQuery = useQuery(
		['conversations', user.userId],
		getConversations,
		{
			staleTime: 0,
		}
	);

	const [form, setForm] = useState({
		title: '',
		content: '',
		files: [],
	});

	const createConvMutation = useMutation(postConversation, {
		onSuccess: () => {
			conversationsQuery.refetch();
			setForm({
				title: '',
				content: '',
				files: [],
			});
			modalControls.close();
		},
	});
	const handleCreateConversation = (e) => {
		e.preventDefault();
		createConvMutation.mutate({
			...form,
			clientReceiver: user.userId,
		});
	};

	useEffect(() => {
		createConvMutation.reset();
	}, [isOpen]);

	const [selectedConversationId, setSelectedConversationId] = useState(null);

	return (
		<div className="white-box white-box--library">
			{selectedConversationId ? (
				<Conversation
					selectedConversationId={selectedConversationId}
					setSelectedConversationId={setSelectedConversationId}
				/>
			) : (
				<div className="white-box__inner">
					<div className="notifications">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: 20,
							}}
						>
							<h2 className="main-title main-title--25">Korespondencja</h2>
							<button
								type="button"
								onClick={modalControls.open}
								className="btn"
							>
								Napisz wiadomość
							</button>
						</div>

						{!conversationsQuery.data?.length && (
							<div className="notifications__heading">Brak Korespondencji</div>
						)}
						<table className="uploaded-materials-table">
							<thead>
								<tr>
									<th className="uploaded-materials-table__th">DATA</th>
									<th className="uploaded-materials-table__th">NADAWCA</th>
									<th className="uploaded-materials-table__th">TEMAT</th>
									<th className="uploaded-materials-table__th">
										TREŚĆ WIADOMOŚCI
									</th>
									<th className="uploaded-materials-table__th">ZAŁĄCZNIKI</th>
									<th className="uploaded-materials-table__th"></th>
								</tr>
							</thead>
							<tbody>
								{conversationsQuery.data?.map((conv) => {
									return (
										<tr key={conv.id}>
											<td
												className="uploaded-materials-table__td "
												style={{
													textAlign: 'center',
												}}
											>
												<div>{formatDateForPresentation(conv.createdAt)}</div>
												<div>{formatTimeForPresentation(conv.createdAt)}</div>
											</td>
											<td className="uploaded-materials-table__td ">
												<div>{conv.creator ? 'gettimages' : 'Pobierający'}</div>
												<div>
													{conv.creator ? '<kontak@gettimages.pl>' : user.email}
												</div>
											</td>
											<td className="uploaded-materials-table__td ">
												<a
													onClick={(e) => {
														e.preventDefault();
														setSelectedConversationId(conv.id);
													}}
													href="/"
													className="invoices__download"
													style={{
														margin: 0,
														display: 'flex',
														gap: 6,
													}}
												>
													{conv.title}
												</a>
											</td>
											<td className="uploaded-materials-table__td ">
												<Text lineClamp={3}>{conv.lastMessage?.content}</Text>
											</td>
											<td className="uploaded-materials-table__td ">
												{conv.lastMessage?.attachments.map((atach) => (
													<a
														key={atach.id}
														href={atach.contentUrl}
														download={atach.originalName}
														className="invoices__download"
														target="_blank"
														style={{
															margin: 0,
															display: 'flex',
															gap: 6,
															marginBottom: 5,
														}}
													>
														<span>{atach.originalName}</span>
														<i className="ico ico-download-icon"></i>
													</a>
												))}
											</td>
											<td className="uploaded-materials-table__td ">
												<button
													onClick={(e) => {
														e.preventDefault();
														setSelectedConversationId(conv.id);
													}}
													className="btn"
													style={{}}
												>
													Przejdź
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			)}

			<Modal
				opened={isOpen}
				withCloseButton={false}
				centered
				onClose={modalControls.close}
				size="35%"
			>
				<div className="modal__inner">
					<div className="modal__wrapper">
						<h3 className="modal__title">Nowy komunikat</h3>
						<a
							className="modal__close-button js-modal-close"
							onClick={modalControls.close}
						>
							<i className="ico ico-cancel"></i>
						</a>
					</div>
					<div
						className="modal__content"
						style={{
							width: '100%',
						}}
					>
						{createConvMutation.isSuccess && (
							<p
								style={{
									color: 'green',
									textAlign: 'center',
									marginTop: 0,
									marginBottom: 10,
								}}
							>
								Zgłoszenie zostało wysłane!
							</p>
						)}
						<form
							className="form"
							style={{
								width: '100%',
							}}
							onSubmit={handleCreateConversation}
						>
							<div className="form__group">
								<div className="form__field">
									<input
										value={form.title}
										onChange={(e) => {
											setForm({
												...form,
												title: e.target.value,
											});
										}}
										type="text"
										placeholder="Tytuł komunikatu"
										className="form__input required"
									/>
								</div>
							</div>
							<div className="form__group">
								<div className="form__field">
									<textarea
										value={form.content}
										onChange={(e) => {
											setForm({
												...form,
												content: e.target.value,
											});
										}}
										rows={7}
										required
										className="form__textarea required"
										placeholder="Treść wiadomości"
									></textarea>
								</div>
							</div>
							{!!form.files.length && (
								<div
									className="form__actions"
									style={{
										marginBottom: 10,
									}}
								>
									<p className="form__actions--info">
										Liczba dodanych obrazów: {form.files.length}
									</p>
								</div>
							)}
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<FileButton
									onChange={(files) => {
										setForm({
											...form,
											files: [...form.files, ...files],
										});
									}}
									multiple
									accept="image/png,image/jpeg,application/msword,application/pdf"
								>
									{(props) => (
										<button
											{...props}
											type="button"
											className="btn  btn--outline"
										>
											+ Załączniki
										</button>
									)}
								</FileButton>

								<button
									disabled={
										createConvMutation.isLoading || !form.title || !form.content
									}
									type="submit"
									className="btn"
								>
									{createConvMutation.isLoading ? <Loader /> : 'Wyślij'}
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Conversations;
