import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import librarySlice from "./slice/librarySlice";
import cartSlice from "./slice/cartSlice";

const store = configureStore({
    reducer: {
        auth : authSlice,
        library: librarySlice,
        cart: cartSlice
    }
})


export default store;