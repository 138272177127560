import React from 'react';
import logo from '../../assets/img/Pobierający/logo.svg';
import { useSelector } from 'react-redux';

const Header = () => {
	return (
		<header className="top">
			<div className="container">
				<div className="flex flex--space-between flex--center-y">
					<div className="top__text">
						…niezywkłe wydarzenia na obrazach odsłoną prawdziwego Świata…
					</div>
					<div className="top__logo">
						<a href="https://gettimages.pl/">
							<img src={logo} alt="gettimages logo" />
						</a>
					</div>
					<div className="top__nav hidden">
						<nav className="nav">
							<ul className="nav__list js-menu">
								<li className="nav__item">
									<a href="#">
										<i className="ico ico-help-icon"></i> Pomoc
									</a>
								</li>
								<li className="nav__item">
									<a href="#">
										<i className="ico ico-upload-icon"></i> Twoja biblioteka
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="top__text top__text--last">
						…jesteśmy po to, aby przybliżać wyjątkowe chwile w realnym Świecie…
					</div>
					<a className="menu-trigger js-mobile-menu visible-sm">
						<span className="menu-trigger__inner">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</span>
					</a>
				</div>
			</div>
		</header>
	);
};

export default Header;
