import React, { useEffect, useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { useQuery, useQueryClient } from 'react-query';
import { IconChevronDown } from '@tabler/icons';
import WhiteBoxItem from './WhiteBoxItem';
import { getImageCategories, getPurchaseItems } from '../../api/get';
import { useSelector } from 'react-redux';

const PER_PAGE = 10;
const CATEGORY_ALL = [
	'1ed17c94-271f-678e-b474-834af8ea4327',
	'1ed17c94-2721-6e94-8bfd-834af8ea4327',
	'1ed17c94-2723-66d6-b752-834af8ea4327',
];

const DownloadedItems = () => {
	const queryClient = useQueryClient();

	const user = useSelector((state) => state.auth.user);
	const [simplesearch, setSimplesearch] = useState('');

	const [sortType, setSortType] = useState('desc');

	const [page, setPage] = useState(1);
	const [chosenCategory, setChosenCategory] = useState('');

	const purchaseItemsQuery = useQuery(
		[
			'purchase_items',
			page,
			user.userId,
			simplesearch,
			sortType,
			chosenCategory,
		],
		getPurchaseItems,
		{
			refetchOnMount: 'always',
			enabled: !!user.userId,
			initialData: {
				total: 0,
				items: [],
			},
		}
	);

	useEffect(() => {
		queryClient.prefetchQuery(
			[
				'purchase_items',
				page + 1,
				user.userId,
				simplesearch,
				sortType,
				chosenCategory,
			],
			getPurchaseItems
		);
		//eslint-disable-next-line
	}, [page, queryClient, user.userId]);

	useEffect(() => {
		setPage(1);
	}, [simplesearch, chosenCategory]);

	const imageCategoriesQuery = useQuery(
		['image_group_categories'],
		getImageCategories,
		{
			initialData: [],
			select: (data) =>
				data.reduce((acc, curr) => {
					for (const cat of curr.subCategories) {
						acc.push({
							value: cat.id,
							label: cat.name,
							group: curr.name,
						});
					}
					return acc;
				}, []),
		}
	);

	const totalPages = Math.ceil(purchaseItemsQuery.data?.total / PER_PAGE);

	return (
		<div>
			<div className="white-box white-box--selectors">
				<div className="white-box__inner">
					<h2 className="main-title hidden-md">Lista pobranych materiałów</h2>
					<div className="select-container">
						<div className="input-wrapper">
							<input
								onChange={(e) => setSimplesearch(e.target.value)}
								type="text"
								name="f-search"
								placeholder="Numer obrazu"
								className="form__input search-input required"
								id="f-search"
								value={simplesearch}
							/>
							<button>
								<i className="ico ico-search-icon"></i>
							</button>
						</div>

						<div className="select-container__additional">
							<div className="select">
								<Select
									disabled={!imageCategoriesQuery.data.length}
									className={'f-sort'}
									value={chosenCategory}
									onChange={(e) => {
										if (!e) return;
										setChosenCategory(CATEGORY_ALL.includes(e) ? '' : e);
									}}
									placeholder="Kategoria"
									data={imageCategoriesQuery.data}
									rightSection={<IconChevronDown size={10} />}
									rightSectionWidth={27}
									styles={{
										rightSection: {
											pointerEvents: 'none',
											marginRight: 20,
											'& svg': {
												strokeWidth: 1,
											},
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
										},
										item: {
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
									}}
								/>
							</div>
							<div className="select">
								<Select
									className={'f-sort'}
									onChange={setSortType}
									value={sortType}
									placeholder="Sortuj"
									data={[
										{
											value: 'desc',
											label: 'Data: malejąco',
										},
										{
											value: 'asc',
											label: 'Data: rosnąco',
										},
									]}
									rightSection={<IconChevronDown size={10} />}
									rightSectionWidth={27}
									styles={(theme) => ({
										rightSection: {
											pointerEvents: 'none',
											marginRight: 20,
											'& svg': {
												strokeWidth: 1,
											},
										},
										input: {
											backgroundColor: '#fff',
											border: '2px solid #BCD3E5',
											borderRadius: 0,
											transition: 'all 0.3s ease-out 0s',
											height: 57,
										},
										item: {
											'&[data-selected]': {
												backgroundColor: '#d0dae2',
												'&, &:hover': {
													backgroundColor: '#d0dae2',
													color: '#fff',
												},
											},
											'&[data-hovered]': {
												backgroundColor: '#364a59',
												color: '#fff',
											},
										},
									})}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{purchaseItemsQuery.isFetching && !purchaseItemsQuery.isFetched && (
				<div
					style={{
						height: '10rem',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Loader size={36} />
				</div>
			)}
			{purchaseItemsQuery.data?.items.length === 0 &&
				purchaseItemsQuery.isFetched && (
					<div className={'white-box'}>
						<div className="white-box__inner">
							<p style={{ margin: 0, padding: 20 }}>
								Brak pobranych obrazów w wybranej kategorii
							</p>
						</div>
					</div>
				)}
			{purchaseItemsQuery.data?.items?.map((item) => (
				<WhiteBoxItem key={item.id} item={item} />
			))}

			{totalPages > 1 && (
				<div className="pagination">
					<div className="pagination__list">
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault();
								setPage((prev) => prev - 1);
							}}
							className={`pagination__item pagination__item--prev ${
								page === 1 ? 'a-disabled' : ''
							}`}
						>
							<span>Poprzednia strona</span>
						</a>
						<a href="/" className="pagination__item">
							<span>{page}</span>
						</a>
						<div className="pagination__item">
							<span>z</span>
						</div>
						<a href="/" className="pagination__item">
							<span>{totalPages}</span>
						</a>
						{totalPages !== page && (
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault();
									setPage((prev) => prev + 1);
								}}
								className="pagination__item pagination__item--next"
							>
								<span className="">Następna strona</span>
							</a>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default DownloadedItems;
