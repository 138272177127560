import Cookies from 'js-cookie';
import { omitBy } from 'lodash';

export const authorizedGet = async (url) => {
	const accessToken = Cookies.get('token') || localStorage.getItem('token');
	const refreshToken =
		Cookies.get('refreshToken') || localStorage.getItem('refreshToken');
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.status === 401) {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/auth/refresh_token`,
				{
					method: 'POST',
					body: JSON.stringify({
						refresh_token: refreshToken,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 401) {
				localStorage.removeItem('isLoggedIn');
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
				Cookies.remove('refreshToken');
				Cookies.remove('token');
			}

			const data = await response.json();
			if (data) {
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
				localStorage.setItem('token', data.token);
				localStorage.setItem('refreshToken', data.refresh_token);
			}
		}

		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		return await response.json();
	} catch (e) {
		// localStorage.removeItem('isLoggedIn');
		// localStorage.removeItem('token');
		// localStorage.removeItem('refreshToken');
		// Cookies.remove('refreshToken', { domain: '.testujemyweby.pl' });
		// Cookies.remove('token', { domain: '.testujemyweby.pl' });
		console.error(e);
		throw new Error(e);
	}
};

export const authorizedGetById = async (url, id = '') => {
	const accessToken = localStorage.getItem('token');
	try {
		if (id) {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}${url}/${id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error('Coś poszło nie tak');
			}

			return await response.json();
		} else {
			throw new Error('Coś poszło nie tak');
		}
	} catch (e) {
		localStorage.removeItem('isLoggedIn');
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		console.error(e);
	}
};
export const authorizedPost = async ({ url, data }) => {
	const accessToken = localStorage.getItem('token');
	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: omitBy(
			{
				'Content-Type': 'application/json',
				Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
			},
			(value) => !value
		),
	});
	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return response;
};

export const authorizedPatchWithId = async ({ url, data }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: omitBy(
			{
				'Content-Type': 'application/merge-patch+json',
				Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
			},
			(value) => !value
		),
	});
	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return await response.json();
};

export const authorizedPutWithId = async ({ url, data, id }) => {
	const accessToken = localStorage.getItem('token');

	const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		},
	});

	if (!response.ok) {
		throw new Error('Coś poszło nie tak');
	}

	return await response.json();
};

export const authorizedDeleteWithId = async ({ url, id }) => {
	const accessToken = localStorage.getItem('token');
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}${url}/${id}`,
			{
				method: 'DELETE',
				headers: omitBy(
					{
						Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
					},
					(value) => !value
				),
			}
		);

		if (!response.ok) {
			throw new Error('Coś poszło nie tak');
		}

		return await response.json();
	} catch (e) {
		console.error(e);
	}
};
