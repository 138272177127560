import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getConversation } from '../../api/get';
import { FileButton, Loader } from '@mantine/core';
import { useSelector } from 'react-redux';
import { formateExactDate, sortByDate } from '../../utils';
import { sendMessage } from '../../api/post';

export default function Conversation({
	selectedConversationId,
	setSelectedConversationId,
}) {
	const queryClient = useQueryClient();

	const { user } = useSelector((store) => store.auth);

	const [form, setForm] = useState({
		content: '',
		files: [],
	});

	const conversationQuery = useQuery(
		['conversations', selectedConversationId],
		getConversation,
		{
			select: (data) => ({
				...data,
				messages: [...data.messages].sort((a, b) =>
					sortByDate(a.createdAt, b.createdAt)
				),
			}),
		}
	);

	const answerMutation = useMutation(sendMessage, {
		onMutate: async (newAnswer) => {
			await queryClient.cancelQueries([
				'conversations',
				selectedConversationId,
			]);

			const prev = queryClient.getQueryData([
				'conversations',
				selectedConversationId,
			]);

			queryClient.setQueryData(['conversations', selectedConversationId], {
				...prev,
				messages: [
					...prev.messages,
					{
						id: 'new',
						content: newAnswer.content,
						createdAt: new Date().toISOString(),
						attachments: [],
						messageCreator: { ...user, id: user.userId },
					},
				],
			});

			return { prev };
		},
		onSettled: () => {
			queryClient.invalidateQueries(['conversations']);
		},
	});
	const handleAnswer = () => {
		setForm({
			content: '',
			files: [],
		});

		answerMutation.mutate({
			...form,
			conversationId: selectedConversationId,
			clientCreator: user.userId,
		});
	};

	if (!conversationQuery.data)
		return (
			<div>
				<Loader />
			</div>
		);

	return (
		<div className="white-box white-box--library">
			<div className="white-box__inner">
				<div>
					<div
						className="invoices__download"
						style={{
							marginLeft: '0',
							marginBottom: '10px',
						}}
					>
						<a onClick={() => setSelectedConversationId(null)}>
							<i className="ico ico ico-chevron-thin-left"></i>
							Wróć do wszystkich wiadomości
						</a>
					</div>
					<h2 className="main-title main-title--25">Korespondencja</h2>
					<h3 className="main-title main-title--25">
						Temat: {conversationQuery.data.title}
					</h3>
					{conversationQuery.data.messages.map((message) => {
						const isAdminMsg = message.messageCreator?.id !== user.userId;

						return (
							<div
								key={message.id}
								style={{
									paddingBlock: '1rem',
									paddingInline: isAdminMsg ? '1rem' : '2.5rem',
									border: isAdminMsg ? '2px solid #bcd3e5' : 'none',
									display: 'flex',
									gap: '1rem',
									marginBottom: '1rem',
									justifyContent: 'space-between',
								}}
							>
								<div>
									<div
										style={{
											display: 'flex',
											gap: 12,
											alignItems: 'center',
											marginBottom: 12,
										}}
									>
										<h4
											style={{
												fontSize: 14,
												fontWeight: 'bold',
												marginBottom: 0,
											}}
										>
											Nadawca:{' '}
											{isAdminMsg
												? `${message.messageCreator?.name} ${message.messageCreator?.surname} <serwis@gettimages.pl>`
												: `Pobierający ${user.userDisplayName} <${user.email}>`}
										</h4>
										<div>{formateExactDate(formateExactDate.createdAt)}</div>
									</div>
									<div className="main-text wysiwyg">
										<p>{message.content}</p>
									</div>
								</div>
								{!!message.attachments.length && (
									<div>
										<h4
											style={{
												fontSize: 14,
												fontWeight: 'bold',
												marginBottom: 12,
											}}
										>
											Załączniki
										</h4>
										{message.attachments.map((atach) => (
											<a
												key={atach.id}
												href={atach.contentUrl}
												download={atach.originalName}
												className="invoices__download"
												target="_blank"
												style={{
													margin: 0,
													display: 'flex',
													gap: 6,
													alignItems: 'center',
													marginBottom: 5,
												}}
											>
												<span>{atach.originalName}</span>
												<i className="ico ico-download-icon"></i>
											</a>
										))}
									</div>
								)}
							</div>
						);
					})}
					<div className="notifications__heading"></div>
					<h3
						className="main-title main-title--25"
						style={{
							marginTop: '2rem',
						}}
					>
						Odpowiedz
					</h3>
					<div className="form__group">
						<div className="form__field">
							<textarea
								value={form.content}
								onChange={(e) => {
									setForm({
										...form,
										content: e.target.value,
									});
								}}
								rows={7}
								required
								className="form__textarea required"
								placeholder="Treść wiadomości"
							></textarea>
						</div>
					</div>
					{!!form.files.length && (
						<div
							className="form__actions"
							style={{
								marginBottom: 10,
							}}
						>
							<p className="form__actions--info">
								Liczba dodanych obrazów: {form.files.length}
							</p>
						</div>
					)}
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<FileButton
							onChange={(files) => {
								setForm({
									...form,
									files: [...form.files, ...files],
								});
							}}
							multiple
							accept="image/png,image/jpeg,application/msword,application/pdf"
						>
							{(props) => (
								<button {...props} type="button" className="btn  btn--outline">
									+ Załączniki
								</button>
							)}
						</FileButton>

						<button
							disabled={answerMutation.isLoading || !form.content}
							type="button"
							className="btn"
							onClick={handleAnswer}
						>
							{answerMutation.isLoading ? <Loader /> : 'Wyślij'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
