import Cookies from 'js-cookie';
import moment from 'moment';

export function clearTokens() {
	localStorage.removeItem('token');
	localStorage.removeItem('refreshToken');

	Cookies.remove('token');
	Cookies.remove('refreshToken');
	Cookies.remove('user');

	Cookies.remove('token', {
		domain: process.env.REACT_APP_DOMAIN,
		path: '/',
	});
	Cookies.remove('refreshToken', {
		domain: process.env.REACT_APP_DOMAIN,
		path: '/',
	});
	Cookies.remove('user', {
		domain: process.env.REACT_APP_DOMAIN,
		path: '/',
	});
}

const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

/**
 *
 * @param password one capital one number one samll speciall and at least 8
 * @returns
 */
export function validatePassword(password) {
	return PASSWORD_REGEX.test(password);
}

export function sortByDate(dateA, dateB, order = 'asc') {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function formatDateForPresentation(date = new Date()) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(date = new Date()) {
	return moment(date).format('HH:mm');
}

export function formateExactDate(date = new Date()) {
	return `${formatDateForPresentation(date)} ${formatTimeForPresentation(
		date
	)}`;
}
