import React from 'react';
import { motion } from 'framer-motion';
import { useMutation } from 'react-query';
import useInput from '../../hooks/useInput';
import { Loader } from '@mantine/core';
import jwt from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/slice/authSlice';
import Cookies from 'js-cookie';

const Login = ({ setIsRegister, setIsPasswordReminder }) => {
	const dispatch = useDispatch();

	const {
		value: enteredLogin,
		hasError: enteredLoginHasError,
		enteredValueChangeHandler: enteredLoginChangeHandler,
	} = useInput((enteredValue) =>
		enteredValue?.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	);

	const {
		value: enteredPassword,
		hasError: enterePasswordeHasError,
		enteredValueChangeHandler: enteredPasswordChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 5);

	const isFormValid = !enteredLoginHasError && !enterePasswordeHasError;

	const loginClient = async ({ email, password }) => {
		await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
			method: 'POST',
			body: JSON.stringify({
				email,
				password,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					throw new Error('Podane dane są niepoprawne!');
				}
				if (response.ok) {
					return response.json();
				}
			})
			.then((data) => {
				const user = jwt(data.token);

				if (user.accountType === 'client' && user.isActive && !user.isBlocked) {
					localStorage.setItem('token', data.token);
					localStorage.setItem('refreshToken', data.refresh_token);

					Cookies.set('token', data.token, {
						secure: true,
						expires: 360,
						domain: '.testujemyweby.pl',
					});

					Cookies.set('refreshToken', data.refresh_token, {
						secure: true,
						expires: 360,
						domain: '.testujemyweby.pl',
					});

					dispatch(authActions.logIn(user));
					setIsRegister(false);
					setIsPasswordReminder(false);
				} else if (user.isBlocked) {
					throw new Error(
						'Twoje konto zostało zablokowane. Skontaktuj się z administratorem.'
					);
				} else if (!user.isActive) {
					throw new Error('Konto jest nieaktywne!');
				} else {
					throw new Error('Podane dane są niepoprawne!');
				}
			});
	};

	const loginClientMutate = useMutation(loginClient);

	const loginHandler = (e) => {
		e.preventDefault();
		loginClientMutate.mutate({
			email: enteredLogin,
			password: enteredPassword,
		});
	};

	return (
		<div className="w-100">
			<h2 className="mp-flex-white__title">Zaloguj się jako pobierający</h2>

			<form onSubmit={loginHandler}>
				<div className="form__group">
					<div className="form__field">
						<input
							onChange={(e) => enteredLoginChangeHandler(e)}
							type="email"
							name="f-email-3"
							placeholder="Adres e-mail"
							className="form__input"
							id="f-email-3"
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="form__field">
						<input
							onChange={(e) => enteredPasswordChangeHandler(e)}
							type="password"
							name="f-pass-3"
							placeholder="Hasło"
							className="form__input"
							id="f-pass-3"
						/>
					</div>
				</div>

				<div className="form__actions mb-20">
					<button disabled={!isFormValid} className="btn btn--block">
						{loginClientMutate.isLoading ? (
							<div>
								<Loader size={12} color={`#ffffff`} />
							</div>
						) : (
							<div>Zaloguj się</div>
						)}
					</button>
				</div>
				{enteredLoginHasError && (
					<div className="form__actions">
						<p className="error">Podany adres email jest niepoprwany!</p>
					</div>
				)}
				{enterePasswordeHasError && (
					<div className="form__actions">
						<p className="error">Podane hasło jest za krótkie!</p>
					</div>
				)}
				{loginClientMutate.isError && (
					<div className="form__actions mb-20">
						<p className="error">{loginClientMutate.error.message}</p>
					</div>
				)}

				<div className="form__group">
					<div className="main-wrapper">
						<div className="account-info-check">
							<span>Zapomniałes hasła?</span>
							<button
								onClick={() => {
									setIsPasswordReminder(true);
								}}
								type={'button'}
							>
								Przypomnij hasło
							</button>
						</div>
						<div className="account-info-check">
							<span>Nie posiadasz konta?</span>
							<button onClick={() => setIsRegister(true)}>
								Zarejestruj się
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Login;
