import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import useInput from "../../hooks/useInput";
import {useMutation} from "react-query";
import {authorizedPatchWithId} from "../../fetchFunctions";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../store/slice/authSlice";
import {Loader} from "@mantine/core";

const EmailChange = () => {
    const [showTooltip, setShowTooltip] = useState(false)
    const dispatch = useDispatch();
    const emailChangeMutation = useMutation(authorizedPatchWithId, {
        onSuccess: () => {
            dispatch(authActions.logOut())
        }
    });
    const user = useSelector(state => state.auth.user);
    const {
        value: enteredEmail,
        isTouched: enteredEmailIsTouched,
        hasError: enteredEmailHasError,
        enteredValueChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEnteredEmail
    } = useInput((enteredValue) => enteredValue?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ));


    const passwordChangeHandler = e => {
        e.preventDefault()
        emailChangeMutation.mutate({
            url: `/clients/${user.userId}/change_email`,
            data: {
                newEmailAwaitingConfirm: enteredEmail,
            }
        })
    }

    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .3
                }
            }}
            className="white-box white-box--library">
            <div className="white-box__inner">
                <div className="passwords">
                    <h2 className="main-title main-title--25">Zmiana maila</h2>

                    <form onSubmit={passwordChangeHandler}>
                        <div className="input-container">
                            <div className="form__group">
                                <div className="form__field">
                                    <input
                                        onChange={e => enteredEmailChangeHandler(e)}
                                        type="email" name="f-current-password" placeholder="Nowy adres email"
                                        className="form__input required" id="f-current-password"/>
                                </div>
                            </div>
                        </div>

                        <div className="form__actions">
                            <button
                                disabled={enteredEmailHasError}
                                type={"submit"}
                                className="btn btn--block">{emailChangeMutation.isLoading ?
                                <div><Loader size={12} color={`#ffffff`}/></div> : <div>Zapisz zmiany</div>}</button>
                        </div>
                        {emailChangeMutation.isError && <div className="form__actions">
                            <motion.p
                                initial={{opacity: 0, y: 5}}
                                animate={{opacity: 1, y: 0}}
                                transition={{
                                    default: {
                                        duration: .3
                                    }
                                }}
                                className="error">Podany email jest już w bazie!
                            </motion.p>
                        </div>}
                    </form>
                </div>
            </div>
        </motion.div>
    );
};

export default EmailChange;