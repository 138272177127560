const authorize = (refreshToken) => {
	return fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh_token`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			refresh_token: refreshToken,
		}),
	});
};

export const authorizedFetch = async (url, opt = {}, overwrite = false) => {
	const accessToken = localStorage.getItem('token');
	const refreshToken = localStorage.getItem('refreshToken');
	const makeRequest = () => {
		return fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
			method: 'GET',
			...opt,
			headers: overwrite
				? {
						...opt.headers,
						Authorization: `Bearer ${accessToken}`,
				  }
				: {
						...opt.headers,
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
				  },
		});
	};

	const response = await makeRequest();
	if (response.status === 401) {
		if (!refreshToken) {
			throw new Error('Fetch error 401 no Token');
		}
		const refreshResponse = await authorize(refreshToken);
		if (refreshResponse.status === 200) {
			const { token, refresh_token } = await refreshResponse.json();
			localStorage.setItem('token', token);
			localStorage.setItem('token', token);
			localStorage.setItem('refreshToken', refresh_token);
			localStorage.setItem('refreshToken', refresh_token);
			return makeRequest();
		}
	}

	if (!response.ok) throw new Error('Fetch generic error');

	return await response.json();
};
